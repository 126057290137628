import React,{useState} from 'react';
import '../Styles/PropertyForm.css';
import Form1 from './Form1';
import Form2 from './Form2';

const PropertyForm = () => {

const l1 = '<div >Let us find the best property for you<br />Just tell us what you want</div>';
const l2 = '<div >Want the best deal for your property.<br />Just list it with ClinnovaEstate</div>';
const [showComponentOne, setShowComponentOne] = useState(true);

function setBtn1()
{
  const element = document.getElementById('t1Btn');
  element.style.backgroundColor = '#007bff';
  element.style.color = 'white';
  const el = document.getElementById('line');
  el.innerHTML = l1;
  if(showComponentOne === false)
  {
    setShowComponentOne(!showComponentOne);
  }
  
  const formContainer = document.getElementById('form-container');
  formContainer.style.backgroundColor = 'yellowgreen';

  const element2 = document.getElementById('t2Btn');
  element2.style.backgroundColor = 'white';
  element2.style.color = 'black';
}
function setBtn2()
{
  const element = document.getElementById('t2Btn');
  element.style.backgroundColor = '#007bff';
  element.style.color = 'white';
  const el = document.getElementById('line');
  el.innerHTML = l2;
  if(showComponentOne === true)
  {
    setShowComponentOne(!showComponentOne);
  }
  
  const formContainer = document.getElementById('form-container');
  formContainer.style.backgroundColor = '#52B6FF';

  const element2 = document.getElementById('t1Btn');
  element2.style.backgroundColor = 'white';
  element2.style.color = 'black';
}


  return (
    <div className="form-container" id='form-container'>
      <div className='toggleBtn'>
        <button id='t1Btn' onClick={() => setBtn1()}  >Looking for a property?</button>
        <button id='t2Btn' onClick={() => setBtn2()}  >Want to list a property?</button>
      </div>
      <div id='line' className='line1'><div >Let us find the best property for you. Just tell us what you want</div></div>
      <div id='fform'>  
      {showComponentOne ? <Form1 /> : <Form2 />}
      </div>
    </div>
  );
  
};

export default PropertyForm;
