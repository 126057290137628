import React, { useState } from 'react';
// import './EditLocation.css'

const EditLocation = () => {
  const [formData, setFormData] = useState({
    securitycode: '',
    id: '',
    name: '',
    gpslat: '',
    gpslong: '',
    contains: '',
  });

  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const resetForm = () => {
    setFormData({
      securitycode: '',
      id: '',
      name: '',
      gpslat: '',
      gpslong: '',
      contains: '',
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const url = 'https://www.clinnovaestates.com/api/editlocation';

    const myformdata = new FormData();
    myformdata.append('securitycode', formData.securitycode);
    myformdata.append('id', formData.id);
    myformdata.append('name', formData.name);
    myformdata.append('gpslat', formData.gpslat);
    myformdata.append('gpslong', formData.gpslong);
    myformdata.append('contains', formData.contains);

    console.log(myformdata);

    fetch(url, {
      method: 'POST',
      body: myformdata,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // Handle the JSON response from the server
        console.log(data);
        // Reset the form after successful submission
        resetForm();
        // Show success message for a few seconds
        setSuccessMessage('Location updated successfully!');
        setTimeout(() => {
          setSuccessMessage('');
        }, 3000);
      })
      .catch((error) => {
        // Handle errors
        console.error('There was a problem with the fetch operation:', error);
      });
  };

  return (
    <div>
      <h2>Edit Location</h2>
      <div className="c03">
        <form className="form04" id="form04" onSubmit={handleSubmit}>
        <label htmlFor="securitycode">Security code:</label>
        <input
          type="text"
          id="securitycode"
          name="securitycode"
          value={formData.securitycode}
          onChange={handleChange}
        />
        <label htmlFor="id">ID:</label>
        <input
          type="text"
          id="id"
          name="id"
          value={formData.id}
          onChange={handleChange}
          required
        />
        <label htmlFor="name">Name of Location:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />

        <label htmlFor="gpslat">GPS Latitude:</label>
        <input
          type="text"
          id="gpslat"
          name="gpslat"
          value={formData.gpslat}
          onChange={handleChange}
          required
        />

        <label htmlFor="gpslong">GPS Longitude:</label>
        <input
          type="text"
          id="gpslong"
          name="gpslong"
          value={formData.gpslong}
          onChange={handleChange}
          required
        />

        <label htmlFor="contains">Contains:</label>
        <textarea
          id="contains"
          name="contains"
          value={formData.contains}
          onChange={handleChange}
          required
        />
          <div style={{ display: 'flex', justifyContent: 'center' }} >
          <button type="submit" style = {{  marginLeft : 10 }}>Submit</button>
          <button type="button" style = {{  marginLeft : 10 }} onClick={resetForm}>Reset</button>
          </div>
        </form>
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      </div>
    </div>
  );
};

export default EditLocation;
