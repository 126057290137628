import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import SearchBar from "../Components/Search";
import DefaultImage from '../assets/property-images/image-not-found.jpg';
import ImageSlider from "../Components/Image";
import "../Styles/PropertyPage.css";
import axios from "axios";

const Properties = () => {
  const locationState = useLocation();
  const navigate = useNavigate();

  const isPropDetailsValid =
    locationState.state && locationState.state.propDetails;
  const detailsFromState = locationState.state?.propDetails ?? [];
  console.log(detailsFromState);
  const locID = locationState.state.locationId;
  const [locationName, setLocationName] = useState();
  const [showShareModal, setShowShareModal] = useState(false);
  const [sharedUrl, setSharedUrl] = useState("");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const requestData={
      ids:`[${locID}]`
    }
    axios({
      method:"post",
      url:'https://www.clinnovaestates.com/api/getlocationsbyids',
      data:requestData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        const cleanedLocationName = response.data[0].name.replace(/["']/g, "");
        setLocationName(cleanedLocationName);
      })
      .catch((error) => {
        console.error("Error fetching location:", error);
      });
  }, [locID]);

  const handleClick = async (property) => {
    if (isPropDetailsValid) {
      navigate("/property-details/", {
        state: {
          propertyId: property.id,
        },
      });
    } else {
      console.error("Invalid propDetails in state");
    }
  };

  const handleShare = (property) => {
    const propertyId = property.id.value;
    const url = `https://www.clinnovaestates.com/property-details/${propertyId}`;
    setSharedUrl(url);
    setShowShareModal(true);
    setCopied(false);
  };

  const closeShareModal = () => {
    setShowShareModal(false);
  };

  const copyURL = async () => {
    try {
      await navigator.clipboard.writeText(sharedUrl);
      setCopied(true);
    } catch (error) {
      console.error('Error copying URL:', error.message);
    }
  };

  return (
    <div className="Properties">
      <Navbar />
      <div className="main-contents">
        <SearchBar locName={locationName} />
        {isPropDetailsValid ? (
          <div className="main-container">
            <div className="property-details">
            {detailsFromState.map((propertyDetails, index) => {
              // Assuming each propertyDetails item is an array of property details
              return propertyDetails.map((propertyData, propertyIndex) => {
                const property = Array.isArray(propertyData) ? propertyData[0] : propertyData;
                let detailsContent;
                try {
                  const parsedDetails = JSON.parse(property.description);
                  detailsContent = (
                    <div>
                      <h5>Description:</h5>
                      {Object.entries(parsedDetails).map(([key, value]) => (
                        <div key={key} className="description-cotainer">
                          <p><strong>{key}:</strong> {value}</p>
                        </div>
                      ))}
                    </div>
                  );
                } catch (error) {
                  detailsContent = (
                    <div>
                      <h5>Description:</h5>
                      <div className="description-cotainer">
                        <p>{property.description}</p>
                      </div>
                    </div>
                  );
                }
                return property && property.name && property.price ? (
                  <div key={index} className="property-card">
                    <div className="image-container">
                      <ImageSlider
                        propertyId={property.id.value}
                        defaultImage={DefaultImage}
                        imgext={propertyData.imgext.split(',')}
                      />
                    </div>
                    <div className="detail-container">
                    <h1 className="prop-name">{property.name}</h1>
                      <div className="details-conatainer">
                        
                        <p>₹ {property.price.toString().length <= 5 ? `${property.price} lacks` : `${(property.price /100000)} lacks`}</p>
                      </div>
                      <div className="additional-details">
                        {detailsContent}
                      </div>
                      <div className="btn-container">
                        <button
                          className="enquire"
                          onClick={() => handleClick(property)}
                        >
                          View Details
                        </button>
                        <button className="share-btn" onClick={() => handleShare(property)}>Share</button>
                      </div>
                    </div>
                  </div>
                ) : null;
              });
              })}
            </div>
          </div>
        ) : (
          <p>No property details available.</p>
        )}
      </div>
      <Footer />
      {showShareModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeShareModal}>&times;</span>
            <p>Share this URL:</p>
            <input type="text" value={sharedUrl} readOnly />
            <button onClick={copyURL}>Copy URL</button>
            {copied && <p>Copied!</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Properties;