import React, { useState } from 'react';
import './PropertyList.css';
import EditProperty from './EditProperty';

const PropertyList = () => {
  const [formData, setFormData] = useState({
    scode: '',
    offset: '',
    limit: '',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [showEditForm, setShowEditForm] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const func2 = () => {
    const url = 'https://www.clinnovaestates.com/api/listproperties';
    const scodeTerm = encodeURIComponent(formData.scode.trim());
    const offsetTerm = encodeURIComponent(formData.offset.trim());
    const limitTerm = encodeURIComponent(formData.limit.trim());

    setLoading(true);
    setError(null);
    setResponseData(null);

    fetch(`${url}?securitycode=${scodeTerm}&offset=${offsetTerm}&limit=${limitTerm}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((resultData) => {
        setResponseData(resultData);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    func2();
  };

  const delete_property = (i) => {
    let url = 'https://www.clinnovaestates.com/api/deleteproperty';
    url = `${url}?securitycode=${formData.scode}&id=${i}`;
    console.log(url);
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((resultData) => {
        console.log('deleted successfuly ', i);
      })
      .catch((error) => {
        console.log('error in deleting ', i);
      })
      .finally(() => {
        func2();
        console.log('deleted ', i);
      });
  };

  const edit_property = (property) => {
    setShowEditForm(true);
    setSelectedProperty(property);
    const listprop = document.getElementById('proplist');
    listprop.style.display = 'none';
  };

  const closeEditForm = () => {
    setShowEditForm(false);
    setSelectedProperty(null);
    const listprop = document.getElementById('proplist');
    listprop.style.display = 'block';
    func2();
  };

  return (
   <>
   <div className="editprop">
   {showEditForm && (
    <EditProperty
      property={selectedProperty}
      scode={formData.scode}
      onClose={closeEditForm}
    />
  )}
   </div>
  <div  id='proplist'>
    <h2>Get property List</h2>
    <div className="c03">
      <form className="form03" id="form03" onSubmit={handleSubmit}>
      <label htmlFor='scode'>Security Code:</label>
      <input
        type='text'
        id='scode'
        name='scode'
        value={formData.scode}
        onChange={handleChange}
        required
      />
      <label htmlFor='offset'>Offset:</label>
      <input
        type='text'
        id='offset'
        name='offset'
        value={formData.offset}
        onChange={handleChange}
        required
      />
      <label htmlFor='limit'>Limit:</label>
      <input
        type='text'
        id='limit'
        name='limit'
        value={formData.limit}
        onChange={handleChange}
        required
      />
        <button type="submit" disabled={loading}>
          {loading ? 'Searching...' : 'Search'}
        </button>
      </form>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      

      {responseData && (
        <div className="propertyBox">
          <h3>Property List:</h3>
          <div className="propertycard-container">
            {responseData.map((property, index) => (
              <div key={index} className="property-card">
                <h4>Name : {property.name}</h4>
                <p>ID: {property.id.value}</p>
                <p>Seller ID: {property.seller_id.value}</p>
                <p>Description: {property.description}</p>
                <p>Price: {property.price}</p>
                <p>type: {property.type}</p>
                <p>Location ID: {property.location.value}</p>
                <p>Image: {property.imgext}</p>
                <p>Listed on: {property.listedon}</p>
                <p>Purpose: {property.propfor}</p>
                <button onClick={() => delete_property(property.id.value)}>Delete</button>
                <button onClick={() => edit_property(property)}>Edit</button>
              </div>
            ))}
          </div>
        </div>
      )}

    </div>
  </div>
   </>
  );
};

export default PropertyList;
