import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import './Dashboard.css'
import { Link } from 'react-router-dom';

const Dashboard = () => {
  
  return (
  
      <div className='cont'>
     <div className="Navbar"><Navbar/></div>
     <div className='main'>
     <div className="showcase">
     <div className="list">1. List of all buyer request <span><Link  to="/admin/buyerreq">&#11166;</Link> </span>  </div>
     <div className="list">2. List of all seller request <span><Link to="/admin/sellerreq">&#11166;</Link> </span></div>
     <div className="list">3. List of interests <span><Link to="/admin/listinterests">&#11166;</Link> </span></div>
     <div className="list">4. Search Location <span><Link to="/admin/searchlocation">&#11166;</Link> </span></div>
     <div className="list">5. Get Location By ID<span><Link to="/admin/locationbyid">&#11166;</Link> </span></div>
     <div className="list">6. Seller List<span><Link to="/admin/sellerlist">&#11166;</Link> </span></div>
     <div className="list">7. Property List<span><Link to="/admin/propertylist">&#11166;</Link> </span></div>
     <div className="list">8. Location List<span><Link to="/admin/locationlist">&#11166;</Link> </span></div>
     </div>
     <div className="passage"></div>
     <div className="taskbox">
     <div className="list2">1. Add new location <span><Link to="/admin/addlocation">&#11192;</Link> </span></div>
     <div className="list2">2. Edit Location <span><Link to="/admin/editlocation">&#11192;</Link> </span></div>
     <div className="list2">3. Add new property <span><Link to="/admin/addproperty">&#11192;</Link> </span></div>
     <div className="list2">4. Add seller <span><Link to="/admin/addseller">&#11192;</Link> </span></div>
     </div>
     </div>
     <div className='Footer'><Footer/></div>
    </div>
    
  )
}

export default Dashboard