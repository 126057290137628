import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import Navbar from './Navbar';
import DefaultImage from '../assets/property-images/image-not-found.jpg';
import EnquiryPopup from './EnquiryPopup';
import ImageSlider from './Image';
import '../Styles/PropertyDetails.css';

const PropertyDetails = (props) => {
  const { id } = useParams();
  const propID = id !== undefined ? id : 0; 
  const location = useLocation();
  const [propertyData, setPropertyData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [sharedUrl, setSharedUrl] = useState("");
  const [copied, setCopied] = useState(false);

  const fetchData = async () => {
    try {
      if ((location.state && location.state.propertyId)||(propID)) {
        const propertyId = location.state?.propertyId?.value || propID;
        console.log(propertyId);
        if (propertyId === undefined) {
          console.error('Property ID is undefined');
          return;
        }
        const apiUrl = `https://www.clinnovaestates.com/api/getpropertydetails?ids=[${propertyId}]`;
        console.log(apiUrl);

        const response = await axios.post(apiUrl);

        if (!response.data || !Array.isArray(response.data) || response.data.length === 0) {
          throw new Error('No valid data received from the API');
        }

        const rawData = response.data[0];

        if (!rawData[0] || !rawData[1]) {
          throw new Error('Incomplete data received from the API');
        }

        const transformedData = {
          id: rawData[0].id.value,
          name: rawData[0].name,
          description: rawData[0].description,
          price: rawData[0].price,
          type: rawData[0].type,
          location: rawData[0].location.value,
          bedroom:rawData[0].bedroom,
          imgext: rawData[0].imgext || 'N/A',
        };
        const requestData={
          ids: `[${transformedData.location}]`
        }
        const locationResponse = await axios({
          method:"post",
          url:'https://www.clinnovaestates.com/api/getlocationsbyids',
          data:requestData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(function (response) {
          console.log(response);
          return response;
        })

        if (!locationResponse.data || locationResponse.data.length === 0) {
          throw new Error('No valid location data received from the API');
        }

        const locationData = locationResponse.data[0];
        const locationName = locationData.name || 'N/A';

        const additionalData = {
          prop_id: rawData[1].prop_id,
          details: rawData[1].details,
          locationName: locationName,
        };

        setPropertyData({ ...transformedData, ...additionalData });
      } else {
        console.error("Invalid propertyId in state");
      }
    } catch (error) {
      console.error('Error fetching data from API:', error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEnquireClick = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleShare = () => {
    const propertyId = propertyData.id;
    const url = `https://localhost:3000/property-details/${propertyId}`;
    setSharedUrl(url);
    setShowShareModal(true);
    setCopied(false);
  };

  const closeShareModal = () => {
    setShowShareModal(false);
  };

  const copyURL = async () => {
    try {
      await navigator.clipboard.writeText(sharedUrl);
      setCopied(true);
    } catch (error) {
      console.error('Error copying URL:', error.message);
    }
  };

  return (
    <div>
      <Navbar/>
      {propertyData ? (
        <div className="property-listing">
          <div className="container">
            <div className="sub-container">
              <h1 className='heading'>{propertyData.name}</h1>
              <div className="details-container">
                <ImageSlider
                    propertyId={propertyData.id}
                    defaultImage={DefaultImage}
                    imgext={propertyData.imgext.split(',')}
                  />
                <div className="details">
                  <div className="description">
                    <p>{propertyData.bedroom} BHK</p>
                    <p>{propertyData.locationName.replace(/["']/g, "")}</p>
                    <p>₹ {propertyData.price.toString().length < 5 ? `${propertyData.price} lacks` : `${(propertyData.price /100000)} lacks`}</p>
                  </div>
                  <div className="additional-details">
                    {(() => {
                      let detailsContent;
                      try {
                        const parsedDetails = JSON.parse(propertyData.description);
                        detailsContent = (
                          <div>
                            {Object.entries(parsedDetails).map(([key, value]) => (
                              <div key={key} className="description-container">
                                <p>
                                  <strong>{key}:</strong> {value}
                                </p>
                              </div>
                            ))}
                          </div>
                        );
                      } catch (error) {
                        detailsContent = (
                          <div>
                            <div className="description-container">
                              <p>{propertyData.description}</p>
                            </div>
                          </div>
                        );
                      }
                      return detailsContent;
                    })()}
                    {(() => {
                      let detailsContent;
                      try {
                        const parsedDetails = JSON.parse(propertyData.details);
                        detailsContent = (
                          <div>
                            {Object.entries(parsedDetails).map(([key, value]) => {
                              if (typeof value === 'string' && (value.startsWith('https://') || value.startsWith('https://'))) {
                                return (
                                  <div key={key} className="description-container">
                                    <p><strong>{key}:</strong> <a className='external-links' href={value}>Click Here</a></p>
                                  </div>
                                );
                              } else {
                                return (
                                  <div key={key} className="description-container">
                                    <p><strong>{key}:</strong> {value}</p>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        );
                      } catch (error) {
                        detailsContent = (
                          <div>
                            <div className="description-container">
                              <p>{propertyData.details}</p>
                            </div>
                          </div>
                        );
                      }
                      return detailsContent;
                    })()}     
                  </div>
                </div>
              </div>
              <h1 className='heading'>{propertyData.name}</h1>
              <div className="btn-container">
                <button className="enquire-btn" onClick={handleEnquireClick} type='submit'>Enquire Now</button>
                <button className="enquire-btn" onClick={handleShare}>Share</button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
      {showPopup && (
        <EnquiryPopup onClose={closePopup} propertyId={propertyData.id} />
      )}
      {showShareModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeShareModal}>&times;</span>
            <p>Share this URL:</p>
            <input type="text" value={sharedUrl} readOnly />
            <button onClick={copyURL}>Copy URL</button>
            {copied && <p>Copied!</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyDetails;
