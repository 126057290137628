import React,{useState} from 'react'
import axios from 'axios'

const Form2 = () => {
  let locationAPI = `https://www.clinnovaestates.com/api/searchlocation`; 
  const API = `https://www.clinnovaestates.com/api/addsellerreq?phone=7398232784&name=Alex&details={location:1,price:34,type:3}`;

  const [formData, setFormData] = useState({
    location: '',
    type: '',
    price: '',
    phone:'',
    name:'',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
    console.log(formData);
    const modifiedlocationAPI = `${locationAPI}?term=${formData.locationID}`;
    // console.log(modifiedlocationAPI);
    axios.get(modifiedlocationAPI)
    .then((res)=>{
      console.log(res.data[0].id.value);
      let x = res.data[0].id.value;
      formData.locationID = x;
      // console.log(formData);

      const modifiedAPI = `${API}`;
      console.log(modifiedAPI);
      axios.post(modifiedAPI)
      .then((response)=>{
        console.log(response);
      })
      .catch((error)=>{
        console.log('Error:', error);
      })
      .finally(()=>{
        console.log("post api is successful")
        console.log(formData);
      })
      

    })
    .catch((error) => {
      // Log errors
      console.error('Error:', error);
    })
    .finally(() => {
      // Log a message when the request is complete (success or failure)
      console.log('Request complete');
      
    });
  };

  return (
    <form onSubmit={handleSubmit}>
        <label htmlFor="location">Location:</label>
        <input
          type="text"
          id="location"
          name="location"
          placeholder='Bhubaneswar'
          value={formData.location}
          onChange={handleChange}
          required
        />

       

        <label htmlFor="type">Property Type:</label>
        <select name="type" id="type" onChange={handleChange} value={formData.type}>
          <option value="0">none</option>
          <option value="1">1 Bhk</option>
          <option value="2">2 Bhk</option>
          <option value="3">3 Bhk</option>
          <option value="4">4 Bhk</option>
          <option value="4">4 Bhk</option>
        </select>

        <label htmlFor="price">Property Price (in Lakh):</label>
        <input
          type="number"
          id="price"
          name="price"
          placeholder='75'
          value={formData.price}
          onChange={handleChange}
          required
        />

        <label htmlFor="phone">Contact number:</label>
        <input
          type="number"
          id="phone"
          name="phone"
          placeholder='7398232784'
          value={formData.phone}
          onChange={handleChange}
          required
        />

        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder='Alex Minch'
          value={formData.name}
          onChange={handleChange}
          required
        />

        <button type="submit">Submit</button>
      </form>
  )
}

export default Form2