import React, { useState } from 'react';
import './SellerList.css'

const SellerList = () => {
  const [formData, setFormData] = useState({
    scode: '',
    offset: '',
    limit: '',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [responseData, setResponseData] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  function func2(){
    
    const url = 'https://www.clinnovaestates.com/api/listsellers';
    const scodeTerm = encodeURIComponent(formData.scode.trim());
    const offsetTerm = encodeURIComponent(formData.offset.trim());
    const limitTerm = encodeURIComponent(formData.limit.trim());

    setLoading(true);
    setError(null);
    setResponseData(null);

    fetch(`${url}?securitycode=${scodeTerm}&offset=${offsetTerm}&limit=${limitTerm}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((resultData) => {
        setResponseData(resultData);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    func2();
  };

  function delete_seller(i)
  {
    let url = 'https://www.clinnovaestates.com/api/deletesellerbyid';
    url = `${url}?securitycode=${formData.scode}&id=${i}`;  
    console.log(url); 
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((resultData) => {
        console.log("deleted successfuly ",i);
      })
      .catch((error) => {
        console.log("eroor in deleting ",i);
      })
      .finally(() => {
        func2();
        console.log("deleted ",i);
      });
  }


  return (
    <div>
      <h2>Get Seller List</h2>
      <div className='c03'>
        <form className='form03' id='form03' onSubmit={handleSubmit}>
        <label htmlFor='scode'>Security Code:</label>
        <input
          type='text'
          id='scode'
          name='scode'
          value={formData.scode}
          onChange={handleChange}
          required
        />
        <label htmlFor='offset'>Offset:</label>
        <input
          type='text'
          id='offset'
          name='offset'
          value={formData.offset}
          onChange={handleChange}
          required
        />
        <label htmlFor='limit'>Limit:</label>
        <input
          type='text'
          id='limit'
          name='limit'
          value={formData.limit}
          onChange={handleChange}
          required
        />
          <button type='submit' disabled={loading}>
            {loading ? 'Searching...' : 'Search'}
          </button>
        </form>

        {error && <p style={{ color: 'red' }}>{error}</p>}

        {responseData && (
          <div className='sellerBox'>
            <h3>Seller List:</h3>
            <div className="sellercard-container">
              {responseData.map((seller, index) => (
                <div key={index} className="seller-card">
                  
                  <h4>Name : {seller.name}</h4>
                  <p>ID: {seller.id.value}</p>
                  <p>Phone: {seller.phone}</p>
                  <p>Details: {seller.details}</p>
                  <button onClick={() => delete_seller(seller.id.value)}>Delete</button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SellerList;
