import React, { useState, useEffect } from "react";
import Autosuggest from "react-autosuggest";
import Select from 'react-select';
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Form1 = (props) => {
  const navigate = useNavigate();

  const initialFormData = {
    name: "",
    type: [0],
    locationID: "",
    innerLocationIds: [],
    pricemax: "",
    pricemin: "",
    except: [],
    sortby: "price",
    sortorder: "DESC",
    purpose: [0],
    propfor: [0],
    bedroom: [0],
    unittype: [0],
    n: "10",
    phone: "",
  };

  const storedFormData = JSON.parse(sessionStorage.getItem("formData")) || {};
  const { locationID: storedLocationID, ...restStoredFormData } = storedFormData;
  const [formData, setFormData] = useState({
    ...initialFormData,
    ...restStoredFormData,
  });
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState("");
  const [locationError, setLocationError] = useState(false);

  useEffect(() => {
    setLocationError(false);
  }, [formData.locationID]);

  useEffect(() => {
    setLocationError(false);
    sessionStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  const getSuggestions = async (inputValue) => {
    
    const searchLocationAPI = `https://www.clinnovaestates.com/api/searchlocation?term=${inputValue}`;

    try {
      const response = await axios.get(searchLocationAPI);
      const locations = response.data;

      const locationNames = locations
        .map((location) => location.name.replace(/"/g, ""))
        .filter((name) =>
          name.toLowerCase().includes(inputValue.toLowerCase())
        );

      setSuggestions(locationNames);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    getSuggestions(value);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = async (event, { suggestion }) => {
    const searchLocationAPI = `https://www.clinnovaestates.com/api/searchlocation?term=${suggestion}`;
    try {
      const response = await axios.get(searchLocationAPI);
      const locationID = response.data;
      const selectedLocationId = locationID[0].id.value;

      const innerLocationIdsAPI = `https://www.clinnovaestates.com/api/getinnerlocationids?id=${selectedLocationId}`;
      const innerLocationIdsResponse = await axios.get(innerLocationIdsAPI);
      const innerLocationIds = innerLocationIdsResponse.data;

      const updatedFormData = {
        ...formData,
        locationID: selectedLocationId,
        innerLocationIds: innerLocationIds,
      };

      sessionStorage.setItem("formData", JSON.stringify(updatedFormData)); // Store updated form data
      setFormData(updatedFormData);
      setValue(suggestion);

    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

  const inputProps = {
    placeholder: "Enter location...",
    value,
    onChange: (event, { newValue }) => {
      setValue(newValue);
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isLocationRequired = true;
    const isLocationSelected = formData.locationID !== "";

    if (isLocationRequired && !isLocationSelected) {
      setLocationError(true);
      return;
    }

    try {
      const innerLocationIds = formData.innerLocationIds;
      const allLocationIds = [...innerLocationIds, formData.locationID];
      const uniqueLocationIds = [...new Set(allLocationIds)];

      const apiRequests = uniqueLocationIds.map((locationId) => {
        const requestData = {
          locationID: locationId,
          pricemax: formData.pricemax*100000 || 0,
          pricemin: formData.pricemin*100000 || 0,
          sortby: "price",
          sortorder: "DESC",
          except: '[]',
          propfor: formData.propfor,
          unitype: formData.unittype.join(','),
          bedroom: formData.bedroom.join(','),
          proptype: formData.type.join(','),
          n: 10,
          phone: formData.phone || 1234567890,
          name: encodeURIComponent(formData.name) || "Guest",
          purpose: formData.purpose,
        };
        return axios({
          method: "post",
          url: "https://www.clinnovaestates.com/api/searchproperty",
          data: requestData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(function (response) {
          return response;
        })
      });

      const responses = await Promise.all(apiRequests);

      const propDetailsArray = responses.map((response) => response.data);
      console.log("propeDetailsArray : ",propDetailsArray);

      const updatedFormData = {
        ...formData,
        locationID: formData.locationID,
      };

      setFormData(updatedFormData);

      navigate("/property", {
        state: {
          propDetails: propDetailsArray,
          locationId: formData.locationID,
          formData: updatedFormData,
        },
      });

      console.log("Post API requests are successful");
    } catch (error) {
      console.log("Error:", error);
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      [name]: value,
    };
    sessionStorage.setItem("formData", JSON.stringify(updatedFormData));
    setFormData(updatedFormData);
  };

  const handleSelectChange = (selectedOption, { name }) => {
    const updatedFormData = {
      ...formData,
      [name]: selectedOption ? selectedOption.map(option => option.value) : [],
    };
    sessionStorage.setItem("formData", JSON.stringify(updatedFormData));
    setFormData(updatedFormData);
  };
  const typeoptions = [
    {value:'1',label:'Commertial'},
    {value:'2', label: 'Residential'},
    {value:'0',label:'Others'},
  ];
  const bedroomOptions= [
    { value: '1', label: '1 BHK' },
    { value: '2', label: '2 BHK' },
    { value: '3', label: '2/3 BHK' },
    { value: '4', label: '2/3/4 BHK' },
    { value: '5', label: '2/5 BHK' },
    { value: '6', label: '3 BHK' },
    { value: '7', label: '3/4 BHK' },
    { value: '8', label: '3/5 BHK' },
    { value: '9', label: '4 BHK' },
    { value: '10', label: '3/5 BHK' },
    { value: '11', label: '3/5 BHK' },
    {value:'0',label:'Others'},
  ]
  const unitOptions=[
    {value:'1',label:'Duplex'},
    {value:'2',label:'Flat'},
    {value:'3',label:'Plot'},
    {value:'0', label:'Others'}
  ]

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="name">Name:</label>
      <input
        type="text"
        id="name"
        name="name"
        placeholder="Alex Minch"
        value={formData.name}
        onChange={handleChange}
      />

      <label htmlFor="phone">Contact number:</label>
      <input
        id="phone"
        name="phone"
        placeholder="7398232784"
        value={formData.phone}
        onChange={handleChange}
      />

      <label htmlFor="type">Property Type:</label>
      <Select
        options={typeoptions}
        isMulti
        name="type"
        value={typeoptions.filter(opt => formData.type.includes(opt.value))}
        onChange={(selectedOption) => handleSelectChange(selectedOption, { name: "type" })}
      />

      <label htmlFor="locationID">Location:</label>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={(suggestion) => suggestion}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={onSuggestionSelected}
      />
      {locationError && <div className="error">* Location is required.</div>}

      <label htmlFor="priceRange">Select Price Range (in Lakhs):</label>
      <div className="price-range">
        <input
          type="number"
          id="pricemin"
          name="pricemin"
          placeholder="95"
          value={formData.pricemin}
          onChange={handleChange}
        />
        <input
          type="number"
          id="pricemax"
          name="pricemax"
          placeholder="100"
          value={formData.pricemax}
          onChange={handleChange}
        />
      </div>

      <label htmlFor="purpose">Purpose:</label>
      <select
        name="purpose"
        id="purpose"
        onChange={handleChange}
        value={formData.purpose}
      >
        <option value="1">Investment</option>
        <option value="2">Residential</option>
        <option value="0">Others</option>
      </select>
      <label htmlFor="propfor">Property For</label>
      <select
        name="propfor"
        id="propfor"
        onChange={handleChange}
        value={formData.propfor}
      >
        <option value="1">Buy</option>
        <option value="2">Rent</option>
        <option value="0">Others</option>
      </select>

      <label htmlFor="unittype">Unit Type</label>
      <Select
        options={unitOptions}
        isMulti
        name="unittype"
        value={unitOptions.filter(opt => formData.unittype.includes(opt.value))}
        onChange={(selectedOption) => handleSelectChange(selectedOption, { name: "unittype" })}
      />

      <label htmlFor="bedroom">Bedroom</label>
      <Select
        options={bedroomOptions}
        isMulti
        name="bedroom"
        value={bedroomOptions.filter(opt => formData.bedroom.includes(opt.value))}
        onChange={(selectedOption) => handleSelectChange(selectedOption, { name: "bedroom" })}
      />

      <button type="submit" className="search-btn">Search</button>
      <div className="explore">Explore &rarr;</div>
    </form>
  );
};

export default Form1;