import React, { useState } from 'react';
import './AddProperty.css'

const AddProperty = () => {
  const [formData, setFormData] = useState({
    securitycode : '',
    name: '',
    seller_id: '',
    description: '',
    price: '',
    type: '',
    locationID: '',
    propfor: '',
    details: '',
    filesno: '',
    rseller_phone: '',
    seller_name: '',
    seller_details: '',
    files : [],
  });

 

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'file' ? Array.from(files) : value,
    }));
  };

 

  const handleSubmit = async (e) => {
    e.preventDefault();

  console.log(formData);

const url = 'https://www.clinnovaestates.com/api/addproperty';

const myformdata = new FormData();
myformdata.append('securitycode',formData.securitycode ); 
myformdata.append('name', formData.name); 
myformdata.append('seller_id', formData.seller_id); 
myformdata.append('description', formData.description); 
myformdata.append('price', formData.price); 
myformdata.append('type', formData.type); 
myformdata.append('locationID', formData.locationID); 
myformdata.append('propfor', formData.propfor); 
myformdata.append('details', formData.details); 
myformdata.append('filesno', formData.filesno); 


// myformdata.append('rseller_phone', formData.rseller_phone); 
// myformdata.append('seller_name', formData.seller_name); 
// myformdata.append('seller_details', formData.seller_details); 
const sfiles = formData.files;
for(let i =0;i<sfiles.length;i++){
  myformdata.append(`file${i+1}`,sfiles[0])
}

console.log(myformdata);


 fetch(url, {
  method: 'POST',
  body: myformdata,
  
})
  .then((response) => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then((data) => {
    // Handle the JSON response from the server
    console.log(data);
  })
  .catch((error) => {
    // Handle errors
    console.error('There was a problem with the fetch operation:', error);
  });


  };
  const resetForm = () => {
    setFormData({
      securitycode: '',
      name: '',
      seller_id: '',
      description: '',
      price: '',
      type: '',
      locationID: '',
      propfor: '',
      details: '',
      filesno: '',
      rseller_phone: '',
      seller_name: '',
      seller_details: '',
      files: [],
    });

    // Reset file input (optional)
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.value = '';
    }
  };

  return (
    <div>
      <h2>Add Property</h2>
      <div className='c03'>
      <form className='form03' id='form03' onSubmit={handleSubmit}>
      <label htmlFor="securitycode">Security code:</label>
      <input
        type="text"
        id="securitycode"
        name="securitycode"
        value={formData.securitycode}
        onChange={handleChange}
        
      />

      <label htmlFor="name">Property Name:</label>
      <input
        type="text"
        id="name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        
      />

      
      <label htmlFor="seller_id">Seller id:</label>
      <input
        type="text"
        id="seller_id"
        name="seller_id"
        value={formData.seller_id}
        onChange={handleChange}
        
      />

      <label htmlFor="description">Property Description:</label>
      <input
        type="text"
        id="description"
        name="description"
        value={formData.description}
        onChange={handleChange}
        
      />
      <label htmlFor="price">Property Price:</label>
      <input
        type="number"
        id="price"
        name="price"
        value={formData.price}
        onChange={handleChange}
        
      />
      <label htmlFor="type">Type:</label>
      <input
        type="number"
        id="type"
        name="type"
        value={formData.type}
        onChange={handleChange}
        
      />
      <label htmlFor="locationID">Location ID:</label>
      <input
        type="number"
        id="locationID"
        name="locationID"
        value={formData.locationID}
        onChange={handleChange}
        
      />
      <label htmlFor="propfor">Purpose:</label>
      <input
        type="number"
        id="propfor"
        name="propfor"
        value={formData.propfor}
        onChange={handleChange}
        
      />
      <label htmlFor="details">Property Details:</label>
      <input
        type="text"
        id="details"
        name="details"
        value={formData.details}
        onChange={handleChange}
      />
      <label htmlFor="filesno">Number of images:</label>
      <input
        type="number"
        id="filesno"
        name="filesno"
        value={formData.filesno}
        onChange={handleChange}
      />


      <label>
            Select files:
            <input
              type="file"
              id="fileInput"
              name="files"
              onChange={handleChange}
              multiple
            />
          </label>

      <label htmlFor="rseller_phone">Seller phone:</label>
      <input
        type="tel"
        id="rseller_phone"
        name="rseller_phone"
        value={formData.rseller_phone}
        onChange={handleChange}
        
      />
      <label htmlFor="seller_name">Seller Name:</label>
      <input
        type="text"
        id="seller_name"
        name="seller_name"
        value={formData.seller_name}
        onChange={handleChange}
        
      />
      <label htmlFor="seller_details">Seller details:</label>
      <input
        type="text"
        id="seller_details"
        name="seller_details"
        value={formData.seller_details}
        onChange={handleChange}
        
      />

      <button type="submit">Submit</button>
    </form>
      </div>
    </div>
  );
};

export default AddProperty;
