import React, { useState } from 'react';
import './AddSeller.css'

const AddSeller = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    details: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  function resetForm() {
    const el = document.getElementById('form03');
    el.reset();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const url = 'https://www.clinnovaestates.com/api/addsellerreq';    

    const myformdata = new FormData(); 
    myformdata.append('name', formData.name); 
    myformdata.append('phone', formData.phone); 
    myformdata.append('details', formData.details); 

    fetch(url, {
      method: 'POST',
      body: myformdata,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // Handle the JSON response from the server
        console.log(data);
        resetForm();

      })
      .catch((error) => {
        // Handle errors
        console.error('There was a problem with the fetch operation:', error);
      });

  };

  return (
    <div>
      <h2>Add Seller</h2>
      <div className='c03'>
      <form className='form03' id='form03' onSubmit={handleSubmit}>
      <label htmlFor="name">Name:</label>
      <input
        type="text"
        id="name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        required
      />

      <label htmlFor="phone">Phone:</label>
      <input
        type="tel"
        id="phone"
        name="phone"
        value={formData.phone}
        onChange={handleChange}
        required
      />

      <label htmlFor="details">Details:</label>
      <textarea
        id="details"
        name="details"
        value={formData.details}
        onChange={handleChange}
        required
      />

      <button type="submit">Submit</button>
    </form>
      </div>
    </div>
  );
};

export default AddSeller;