import React, { useEffect, useState } from 'react';
import './Listinterests.css';
import ImageSlider from './ImageSlider2';
import {toast} from 'react-toastify'
import axios from 'axios'


const DefaultImage = ''

const Listinterests = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [propertyDetails, setPropertyDetails] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [locationName,setLocationName] = useState(null);
  const [buyerIdArray,setBuyerIdArray] = useState([]);
  const [secCode,setSecCode] = useState(null);
  const [buyerDetails,setBuyerDetails] = useState(null);

  var userInput = '';
    const preFetchAction = async () => {
   userInput = window.prompt('Enter the Security Code:');
   setSecCode(userInput);
    return true;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (userInput === '') {
        await preFetchAction();
      }
      // console.log("userinput", userInput);
      let apiUrl = 'https://www.clinnovaestates.com/api/listinterests?';
      apiUrl = apiUrl + 'securitycode=' + userInput;

      fetch(apiUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(resultData => {
          setData(resultData);
          // console.log(resultData,typeof(resultData));
        })
        .catch(error => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    fetchData();
  }, []);

  async  function getLocation(ID){

    const requestData={
          ids: ID
        }
        const locationResponse = await axios({
          method:"post",
          url:'https://www.clinnovaestates.com/api/getlocationsbyids',
          data:requestData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(function (response) {
          // console.log(response);
          setLocationName(response); 
        })

  }

  async function getPropDetails(ID) {
   
    const url = `https://www.clinnovaestates.com/api/getpropertydetails?ids=[${ID}]`;
    let fl = true;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      // console.log(responseData);
      // console.log('got some response');
      
      if(responseData[0] !== null && responseData[0][0] !== null)
      {
        // console.log('found data');
        // propertyDetails.push(responseData[0][0]);
        // setPropertyDetails(propertyDetails);
        setPropertyDetails(current => [...current,responseData[0][0]]);
        fl= true;
      }
      else{
        // data not found
        // propertyDetails.push(null);
        // setPropertyDetails(propertyDetails);
        setPropertyDetails(current => [...current,null]);
        fl = true;
      }

    } catch (error) {
      // console.error('There was a problem with the fetch operation for getpropdetails:', error);
      // setPropertyDetails(null); // Reset details if there's an error
      propertyDetails.push(null);
        if(fl === false)
        {
        setPropertyDetails(current => [...current,null]);
        // console.log(propertyDetails);
        }
    }
  };

    const [flag,setFlag] = useState(true);
    
    async function f1()
    {
      // Fetch request
      const formData = new FormData();
      formData.append('securitycode', secCode);
      formData.append('ids', JSON.stringify(buyerIdArray));
      fetch(`https://www.clinnovaestates.com/api/getbuyersbyid`, {
        method: 'POST',
        body: formData
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // Parsing JSON response
      })
      .then(data => {
        // console.log('Response from server:', data);
        setBuyerDetails(data);
      })
      .catch(error => {
        // console.error('There was a problem with the fetch operation:', error);
      });


    }

  
        useEffect(() => {
          if(flag && data !== null)
        {

          data.map((item) => (
            getPropDetails(item.prop_id)
          ))
            // console.log('Properties',propertyDetails);

            data.map((item) => (
            setBuyerIdArray(current => [...current,item.buyer_id])
          ))
          
          setFlag(false);
        }

      }, [data]);

    
      useEffect(()=>{
        // console.log(buyerIdArray);
        // console.log(secCode);
        f1()

      },[buyerIdArray])

      
 
  const closePopup = () => {
    setShowPopup(false);
    setPropertyDetails(null);
  };

  const purp = {
    '0' : 'others',
    '1' : 'Investment',
    '2' : 'Residential'
  }

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {data && (
        <div className='container01'>
          <h1>List of Interests</h1>
          <ul>
            {data.map((item,index) => (
              <div className='box01' key={item.id.value}>
                <li>
                  <div>S.NO : {item.id.value}</div>
                  <div>Buyer_id : {item.buyer_id}</div>
                  <div>Property_id : {item.prop_id}</div>
                  <div >Request Added On : {item.addedon}</div>
                  {!flag && (
                    propertyDetails[index] && (
                      <>
                      <div>Name Of Property: {propertyDetails[index].name}</div>
                      <div>Description: {propertyDetails[index].description}</div>
                      </>  
                    ) 
                  )}
                  {buyerDetails && (
                    <>
                    <div>Buyer Name: {buyerDetails[index].name}</div>
                    <div>Buyer Phone num: {buyerDetails[index].phone}</div>
                    <div>Purpose of Interest: {purp[buyerDetails[index].purpose]}</div>
                    </>
                  )}
                </li>
              </div>
            ))}
          </ul>
        </div>
      )}
      
     
    </div>
  );
}

export default Listinterests;
