import React, { useState } from 'react'
import './App.css'
import Navbar from './Components/Navbar'
import PropertyForm from './Components/PropertyForm'
import Footer from './Components/Footer'
import { Link } from 'react-router-dom';
import Modal from './Components/AddBuyerReq';

const App = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
   
    <div>
    <div className="body">
    
      <div className="title">
        <div className="boxx">
        <div className='company'>ClinnovaEstates</div>
        <div className="info">Your Trusted RealEstate Provider</div>
        </div>
      </div>
      <div className='boxe'>
      
       <div className="add-buyer-req">
         <div onClick={openModal}>
         <div className="abreq">
         <img id='thoughtImg' src="https://res.cloudinary.com/du1n94yvw/image/upload/v1706258982/nh6phns0ti1pvqmgcksj.svg" alt="" />
         <img className='girl-img' src="https://res.cloudinary.com/du1n94yvw/image/upload/v1706151577/images/i93rwuxoi0tqvmki85m8.png" alt="" />
         </div>
         {isModalOpen && <Modal onClose={closeModal} />}
         </div>   
       </div>
      

      <div className="propForm">
        <div className='propertyform'><PropertyForm/></div>
        

        </div>
      </div>
      
    </div>
    </div>
    <Footer/>
    
    </>
    
  )
}
export default App
