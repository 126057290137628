import React, { useEffect, useState } from 'react'
import './SellerReq.css'

const SellerReq = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    var userInput = '';
    const preFetchAction = async () => {
      userInput = window.prompt('Enter the Security Code:');
      return true;
    };
  
    useEffect(() => {

      const fetchData = async () => {

        if(userInput === '')
        {
          await preFetchAction();
        }
        console.log("userinput",userInput);
      let apiUrl = 'https://www.clinnovaestates.com/api/listsellerreq?';
      apiUrl = apiUrl + 'securitycode=' + userInput;

  
      fetch(apiUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(resultData => {
          setData(resultData);
        })
        .catch(error => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });

      };
    fetchData();

    }, []);
  
    return (
      <div>
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error.message}</p>}
        {data && (
          <div className='container01'>
          <h1>List of All Seller Request</h1>
            <ul>
              {data.map(item => (
               <div className='box01'>
               <li key={item.id.value}>
               <div>S.NO : {item.id.value}</div>
               <div>Name : {item.name}</div>
               <div>Phone No : {item.phone}</div>
               <div>Details : {item.details}</div>
               <div>Request Added On : {item.addedon}</div>
               </li>
               
               </div>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
}

export default SellerReq