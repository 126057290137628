import React, { useState } from 'react';

const SearchLocation = () => {
  const [formData, setFormData] = useState({
    name: '',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [responseData, setResponseData] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const url = 'https://www.clinnovaestates.com/api/searchlocation';
    const searchTerm = encodeURIComponent(formData.name.trim()); // Encode the search term

    setLoading(true);
    setError(null);
    setResponseData(null);

    fetch(`${url}?term=${searchTerm}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((resultData) => {
        setResponseData(resultData);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <h2>Search location (get id of location)</h2>
      <div className='c03'>
        <form className='form03' id='form03' onSubmit={handleSubmit}>
          <label htmlFor='name'>Name:</label>
          <input
            type='text'
            id='name'
            name='name'
            value={formData.name}
            onChange={handleChange}
            required
          />
          <button type='submit' disabled={loading}>
            {loading ? 'Searching...' : 'Search'}
          </button>

          {error && <p style={{ color: 'red' }}>{error}</p>}
          {responseData && (
            <div>
              <h3>Response:</h3>
              <pre>{JSON.stringify(responseData, null, 2)}</pre>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default SearchLocation;

