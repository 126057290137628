import React, { useState } from 'react';
import './AddLocation.css'

const AddLocation = () => {
  const [formData, setFormData] = useState({
    securitycode : '',
    name: '',
    gpslat: '',
    gpslong: '',
    contains: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  function resetForm() {
    const el = document.getElementById('form04');
    el.reset();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const url = 'https://www.clinnovaestates.com/api/addlocation';
    
    const myformdata = new FormData();
    myformdata.append('securitycode',formData.securitycode ); 
    myformdata.append('name', formData.name);
    myformdata.append('gpslat', formData.gpslat);  
    myformdata.append('gpslong', formData.gpslong); 
    myformdata.append('contains', formData.contains); 

    console.log(myformdata);

 fetch(url, {
  method: 'POST',
  body: myformdata,
  
})
  .then((response) => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then((data) => {
    // Handle the JSON response from the server
    console.log(data);
  })
  .catch((error) => {
    // Handle errors
    console.error('There was a problem with the fetch operation:', error);
  });


     
  };

  return (
    <div>
      <h2>Add New Location</h2>
      <div className='c03'>
      <form className='form04' id='form04' onSubmit={handleSubmit}>
      <label htmlFor="securitycode">Security code:</label>
      <input
        type="text"
        id="securitycode"
        name="securitycode"
        value={formData.securitycode}
        onChange={handleChange}
      />
      <label htmlFor="name">Name of Location:</label>
      <input
        type="text"
        id="name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        required
      />
      
      <label htmlFor="gpslat">GPS Latitude:</label>
      <input
        type="text"
        id="gpslat"
        name="gpslat"
        value={formData.gpslat}
        onChange={handleChange}
        required
      />

      <label htmlFor="gpslong">GPS Longitude:</label>
      <input
        type="text"
        id="gpslong"
        name="gpslong"
        value={formData.gpslong}
        onChange={handleChange}
        required
      />

      <label htmlFor="contains">Contains:</label>
      <textarea
        id="contains"
        name="contains"
        value={formData.contains}
        onChange={handleChange}
        required
      />

      <button type="submit" >Submit</button>
    </form>
      </div>
    </div>
  );
};

export default AddLocation;