import React, { useState, useRef } from 'react';

const ImageSlider = ({ propertyId, defaultImage, imgext }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const sliderRef = useRef(null);

  const generateImageUrls = () => {
    if (imgext) {
      const imageVector = imgext.map((_, index) => index + 1);

      if (imageVector.length > 1) {
        return imageVector.map((index) => `https://www.clinnovaestates.com/media/prop/${propertyId}_${index}.${imgext[0]}`);
      } else {
        return [`https://www.clinnovaestates.com/media/prop/${propertyId}_1.${imgext[0]}`];
      }
    }

    return [defaultImage];
  };

  const handlePrevImage = () => {
    const totalImages = generateImageUrls().length;
    const newIndex = (currentImageIndex - 1 + totalImages) % totalImages;
    setCurrentImageIndex(newIndex);
    const newScrollPosition = newIndex * sliderRef.current.offsetWidth; 
    sliderRef.current.scrollTo({ left: newScrollPosition, behavior: 'smooth' });
  };
  

  const handleNextImage = () => {
    const totalImages = generateImageUrls().length;
    const newIndex = (currentImageIndex + 1) % totalImages;
    setCurrentImageIndex(newIndex);
    const newScrollPosition = newIndex === 0 ? 0 : sliderRef.current.scrollLeft + sliderRef.current.offsetWidth;
    sliderRef.current.scrollTo({ left: newScrollPosition, behavior: 'smooth' });
  };

  return generateImageUrls().length > 1 ? (
    <div className="image-slider-container">
      <button className="prev" onClick={handlePrevImage}>&#10094;</button>
      <div className="image-slider" ref={sliderRef}>
        {generateImageUrls().map((imageUrl, index) => (
          <img
            key={index}
            src={imageUrl}
            className={`image ${index === currentImageIndex ? 'active' : ''}`}
            alt={`Image Not Found`}
          />
        ))}
      </div>
      <button className="next" onClick={handleNextImage}>&#10095;</button>
    </div>
  ) : (
    <img
      src={generateImageUrls()[0]}
      className="single-image"
      onError={(e) => {
        e.target.src = defaultImage;
      }}
      alt={`Image Not Found`}
    />
  );
};

export default ImageSlider;