import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Properties from "./pages/Properties";
import Agents from "./pages/Agents";
import Contact from "./pages/Contact";
import Dashboard from "./admin/Dashboard.jsx";

import PropertyDetails from "./Components/PropertyDetails";


import { createBrowserRouter, RouterProvider} from "react-router-dom";
import BuyerReq from "./admin/BuyerReq.jsx";
import SellerReq from "./admin/SellerReq.jsx";
import Listinterests from "./admin/ListInterests.jsx";
import AddSeller from "./admin/AddSeller.jsx";
import AddLocation from "./admin/AddLocation.jsx";
import AddProperty from "./admin/AddProperty.jsx";
import SearchLocation from "./admin/SearchLocation.jsx";
import LocationById from "./admin/LocationById.jsx";
import EditLocation from "./admin/EditLocation.jsx";
import SellerList from "./admin/SellerList.jsx";
import PropertyList from "./admin/PropertyList.jsx";
import LocationList from "./admin/LocationList.jsx";
import AddBuyerReq from "./Components/AddBuyerReq.jsx";
import PropertyForm from "./Components/PropertyForm.jsx";
import Form1 from "./Components/Form1.jsx";

import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/property",
    element: <Properties />,
  },
  {
    path:"/edit-search",
    element:<PropertyForm />,
  },
  {
    path: "/property-details",
    element: <PropertyDetails />,
  },
  {
    path: "/agent",
    element: <Agents />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/admin",
    element: <Dashboard />,
  },
  {
    path: "/admin/buyerreq",
    element: <BuyerReq />,
  },
  {
    path: "/admin/sellerreq",
    element: <SellerReq />,
  },
  {
    path: "/admin/listinterests",
    element: <Listinterests />,
  },
  {
    path: "/admin/addseller",
    element: <AddSeller />,
  },
  {
    path: "/admin/addlocation",
    element: <AddLocation />,
  },
  {
    path: "/admin/addproperty",
    element: <AddProperty />,
  },
  {
    path: "/admin/searchlocation",
    element: <SearchLocation />,
  },
  {
    path: "/admin/locationbyid",
    element: <LocationById />,
  },
  {
    path: "/admin/editlocation",
    element: <EditLocation/>,
  },
  {
    path: "/admin/sellerlist",
    element: <SellerList/>,
  },
  {
    path: "/admin/propertylist",
    element: <PropertyList/>,
  },
  {
    path: "/admin/locationlist",
    element: <LocationList/>,
  },
  {
    path: "/addbuyerreq",
    element: <AddBuyerReq/>,
  },
  {
    path:"/property-details/:id",
    element:<PropertyDetails />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <ToastContainer/>
  </React.StrictMode>
);
