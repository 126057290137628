import React, { useState } from 'react';

const LocationById = () => {
  const [formData, setFormData] = useState({
    ids: '',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [responseData, setResponseData] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const url = 'https://www.clinnovaestates.com/api/getlocationsbyids';

    setLoading(true);
    setError(null);
    setResponseData(null);


      const myformdata = new FormData();
    myformdata.append('ids',formData.ids ); 
    

    // console.log(myformdata);

 fetch(url, {
  method: 'POST',
  body: myformdata,
  
})
  .then((response) => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then((resultData) => {
    // Handle the JSON response from the server
    setResponseData(resultData);
  })
  .catch((error) => {
    // Handle errors
    setError(error.message);
    console.error('There was a problem with the fetch operation:', error);
  })
  .finally(() => {
    setLoading(false);
  });

  };

  return (
    <div>
      <h2>Get Location By id</h2>
      <div className='c03'>
        <form className='form03' id='form03' onSubmit={handleSubmit}>
          <label htmlFor='ids'>ID (pass it as vector i.e like this[id]):</label>
          <input
            type='text'
            id='ids'
            name='ids'
            value={formData.ids}
            onChange={handleChange}
            required
          />
          <button type='submit' disabled={loading}>
            {loading ? 'Searching...' : 'Search'}
          </button>

          {error && <p style={{ color: 'red' }}>{error}</p>}
          {responseData && (
            <div>
              <h3>Response:</h3>
              <pre>{JSON.stringify(responseData, null, 2)}</pre>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default LocationById;

