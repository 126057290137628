import React, { useState,useEffect } from 'react';
import '../Styles/AddBuyerReq.css';
import Autosuggest from "react-autosuggest";
import axios from "axios";


const AddBuyerReq = ({onClose}) => {

  const initialFormData ={
    phone: '',
    name: '',
    purpose: '0',
    req: '',
    locationID: '',
  };

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  
  const storedFormData = JSON.parse(sessionStorage.getItem("formData")) || {};
  const { locationID: storedLocationID, ...restStoredFormData } = storedFormData;
  const [formData, setFormData] = useState({
    ...initialFormData,
    ...restStoredFormData,
  });
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState("");
  const [locationError, setLocationError] = useState(false);

  useEffect(() => {
    setLocationError(false);
  }, [formData.locationID]);

  useEffect(() => {
    setLocationError(false);
    sessionStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  const getSuggestions = async (inputValue) => {
    
    const searchLocationAPI = `https://www.clinnovaestates.com/api/searchlocation?term=${inputValue}`;

    try {
      const response = await axios.get(searchLocationAPI);
      const locations = response.data;

      const locationNames = locations
        .map((location) => location.name.replace(/"/g, ""))
        .filter((name) =>
          name.toLowerCase().includes(inputValue.toLowerCase())
        );

      setSuggestions(locationNames);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    getSuggestions(value);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = async (event, { suggestion }) => {
    const searchLocationAPI = `https://www.clinnovaestates.com/api/searchlocation?term=${suggestion}`;
    try {
      const response = await axios.get(searchLocationAPI);
      const locationID = response.data;
      const selectedLocationId = locationID[0].id.value;

      const innerLocationIdsAPI = `https://www.clinnovaestates.com/api/getinnerlocationids?id=${selectedLocationId}`;
      const innerLocationIdsResponse = await axios.get(innerLocationIdsAPI);
      const innerLocationIds = innerLocationIdsResponse.data;

      const updatedFormData = {
        ...formData,
        locationID: selectedLocationId,
        innerLocationIds: innerLocationIds,
      };

      sessionStorage.setItem("formData", JSON.stringify(updatedFormData)); // Store updated form data
      setFormData(updatedFormData);
      setValue(suggestion);

    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const renderSuggestion = (suggestion) => <span className='suggestionBox'>{suggestion}</span>;

  const inputProps = {
    placeholder: "Enter location...",
    value,
    onChange: (event, { newValue }) => {
      setValue(newValue);
    },
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const resetForm = () => {
    setFormData({
      phone: '',
      name: '',
      purpose: '0',
      req: '',
      locationID: '',
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // const isLocationRequired = true;
    const isLocationSelected = formData.locationID !== "";
  
    if (isLocationSelected === false) {
      setErrorMessage('Select the correct Location');
      setLocationError(true);
      return;
    }

    const url = 'https://www.clinnovaestates.com/api/addbuyerreq';

    const myformdata = new FormData();
    myformdata.append('phone', formData.phone);
    myformdata.append('name', formData.name);
    myformdata.append('purpose', formData.purpose);
    myformdata.append('req', formData.req);
    myformdata.append('locationID', formData.locationID);

    fetch(url, {
      method: 'POST',
      body: myformdata,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // Handle the JSON response from the server
        console.log(data);
        setSuccessMessage('Buyer request submitted successfully!');
        setErrorMessage('');
        resetForm();
        setTimeout(() => {
          setSuccessMessage('');
        }, 3000); // Display success message for 3 seconds
      })
      .catch((error) => {
        // Handle errors
        console.error('There was a problem with the fetch operation:', error);
        setErrorMessage('Error submitting buyer request. Please try again.');
        setSuccessMessage('');
        setTimeout(() => {
          setErrorMessage('');
        }, 3000); // Display error message for 3 seconds
      });
  };

  return (

    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        
    <div>
    <h2>Add Buyer Request</h2>
    <div className="c03">
      <form className="form04" id="form04" onSubmit={handleSubmit}>
        <label htmlFor="phone">Phone Number:</label>
        <input
          type="tel"
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
        />
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />

        <label htmlFor="purpose">Purpose:</label>
        <select
          name="purpose"
          id="purpose"
          onChange={handleChange}
          value={formData.purpose}
        >
          <option value="0">Rent</option>
          <option value="1">Buy</option>
        </select>

        <label htmlFor="req">Request:</label>
        <input
          type="text"
          id="req"
          name="req"
          value={formData.req}
          onChange={handleChange}
          required
        />
        <label htmlFor="locationID">Location:</label>
        <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={(suggestion) => suggestion}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={onSuggestionSelected}
      />

        <button type="submit">Submit</button>
        <button onClick={onClose}>Close</button>
      </form>

      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </div>
  </div>
        
      </div>
    </div>


  );
};

export default AddBuyerReq;
