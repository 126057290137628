import React, { useState } from 'react';
import myImage from '../assets/logo.jpg';
import '../Styles/Navbar.css'
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <nav className={`navbar ${isMobileMenuOpen ? 'mobile-open' : ''}`}>
      <div className="box">
        <div className="logo">
          <img src={myImage} alt="Company Logo" />
        </div>
        <div className="mobile-toggle" onClick={toggleMobileMenu}>
          ☰
        </div>
      </div>
      <ul className={`nav-links ${isMobileMenuOpen ? 'mobile-open' : ''}`}>
        <div className="close-icon" onClick={closeMobileMenu}><span>&times;</span></div>
        <li className='home'><Link to="/" onClick={closeMobileMenu}>Home</Link></li>
        <li><Link to="/agent" onClick={closeMobileMenu}>Agents</Link></li>
        <li><Link to="/contact" onClick={closeMobileMenu}>Contact</Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;
